import React from 'react';
import {
  oneOfType, node, arrayOf, string,
} from 'prop-types';

export const wrapRootElement = ({ element }) => (
  <div>
    {element}
  </div>
);

export default wrapRootElement;

wrapRootElement.propTypes = {
  element: oneOfType([string, node, arrayOf(node)]).isRequired,
};
