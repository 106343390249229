exports.components = {
  "component---src-templates-404-index-js": () => import("./../../../src/templates/404/index.js" /* webpackChunkName: "component---src-templates-404-index-js" */),
  "component---src-templates-all-news-index-js": () => import("./../../../src/templates/AllNews/index.js" /* webpackChunkName: "component---src-templates-all-news-index-js" */),
  "component---src-templates-dynamic-page-index-js": () => import("./../../../src/templates/DynamicPage/index.js" /* webpackChunkName: "component---src-templates-dynamic-page-index-js" */),
  "component---src-templates-event-news-index-js": () => import("./../../../src/templates/EventNews/index.js" /* webpackChunkName: "component---src-templates-event-news-index-js" */),
  "component---src-templates-fight-card-index-js": () => import("./../../../src/templates/FightCard/index.js" /* webpackChunkName: "component---src-templates-fight-card-index-js" */),
  "component---src-templates-home-index-js": () => import("./../../../src/templates/Home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */),
  "component---src-templates-news-index-js": () => import("./../../../src/templates/News/index.js" /* webpackChunkName: "component---src-templates-news-index-js" */),
  "component---src-templates-search-index-js": () => import("./../../../src/templates/Search/index.js" /* webpackChunkName: "component---src-templates-search-index-js" */)
}

